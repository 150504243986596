var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ProgramIntroView" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.user && _vm.stateProgram === _vm.state.new
          ? _c("NewProgram", {
              attrs: { title: _vm.title, "program-id": _vm.programId }
            })
          : _vm._e(),
        _vm.user && _vm.stateProgram === _vm.state.pending
          ? _c("ContinueProgram", {
              attrs: {
                title: _vm.title,
                "program-id": _vm.programId,
                "report-id": _vm.reportId
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }