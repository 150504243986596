<template>
  <div class="PaginationBullets">
    <div
      v-for="(bullet, i) in bullets"
      :key="bullet"
      class="bullet"
      :class="[{current: i === current},{completed: i < current}]"
    />
  </div>
</template>

<script>
export default {
  props: {
    bullets: {
      type: Number,
      default: 0,
    },

    current: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.PaginationBullets{
  display: grid;
  //justify-content: center;
  gap: 0.4rem;
  grid-auto-flow: column;
  grid-auto-columns: $spacing-s;

  .bullet{
    background: $color-neutral-mid;
    height: $spacing-s;
    border-radius: 50px;
    transition: all .3s ease;

    &.current{
      background: $color-primary;
      box-shadow: 0 0 1px 1px #fff, 0 0 5px 2px $color-primary-light;
    }

    &.completed{
      background: $color-primary-light;
    }

  }
}
</style>
