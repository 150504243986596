var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "NewProgram" }, [
    _c("h1", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.$t("TITLE.PROGRAM." + _vm.title)) + " ")
    ]),
    _c("div", {
      staticClass: "description",
      domProps: { innerHTML: _vm._s(_vm.textDescriptionProgram) }
    }),
    _c("img", {
      staticClass: "image",
      attrs: {
        src: require("@/assets/images/imagenModal" + _vm.programId + ".jpg")
      }
    }),
    _c(
      "div",
      { staticClass: "button" },
      [
        _c(
          "BaseButton",
          {
            staticClass: "action",
            attrs: { size: "l" },
            on: {
              click: function($event) {
                return _vm.gotoProgram()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("BUTTON.CONTINUE")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }