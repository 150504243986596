<template>
  <ul class="BreadScrumbs">
    <li
      v-for="(breadcrumb, i) in breadcrumbs"
      :key="i"
    >
      <span
        v-if="!isLast(i) && breadcrumb.route"
        class="link"
        variation="tertiary"
        @click="$router.push(breadcrumb.route)"
      >
        {{ breadcrumb.name }}
      </span>

      <span v-else>
        {{ breadcrumb.name }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: undefined,
    },
  },

  methods: {
    isLast(position) {
      return (position === (this.breadcrumbs.length - 1));
    },
  },
};
</script>

<style lang="scss" scoped>
.BreadScrumbs{
  display: flex;
  gap: $spacing-xs;

  span.link{
    color: #fff;
    transition: all .3s ease;

    &::after{
      content: "/";
      color: #fff;
    }

    &:hover{
      cursor: pointer;
      color: $color-primary-lighter;
    }

  }
  span:not(.link){
    color: $color-primary-lighter;
    // font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
