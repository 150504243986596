<template>
  <div class="ProgramView">
    <SubheaderProgram />

    <TransitionFade>
      <div
        v-if="title"
        class="block-title"
      >
        <TransitionSlide side="left">
          <div
            :key="title"
            class="title"
          >
            <img
              class="image"
              :src="require(`@/assets/iconos/${iconBlock}`)"
              alt="logo"
            >
            <p>{{ $t(title) }} </p>
          </div>
        </TransitionSlide>
      </div>
    </TransitionFade>

    <div class="content window-fit">
      <form
        @input="
          validation($event)"
      >

        <div
          v-if="!isLoading"
          class="block-content"
        >
          <TransitionSlide>
            <div
              class="template window-fit"
              :class="[blockTemplate]"
            >
              <BlockQuestion
                v-for="(question, i) in questions"
                :key="i"
                :question="question"
                :value="getAnswer(question.id, question.key)"
                :errors="errors"
                :temporary="isTemporaryAnswer(question.id)"
                :allow-modify-answers="true"
                @answer-selected="answerSelected"
                @force-redirect="submitBlock"
              >
                <ValidationError
                  :errors="errors"
                  :name="question.key"
                />
              </BlockQuestion>
            </div>
          </TransitionSlide>
        </div>
        <div v-else>
          <BaseSpinner />
        </div>

        <TransitionFade>
          <BlockPagination
            v-if="currentBlock && blocks && !isLoading"
            :blocks="blocks"
            :block-id="currentBlock.id"
            @change-block="changeBlock"
            @submit-block="submitBlock"
          />
        </TransitionFade>
      </form>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import SubheaderProgram from '@/components/subheader/SubheaderProgram';
import TransitionSlide from '@/transitions/TransitionSlide';
import BlockQuestion from '@/components/blocks/BlockQuestion';
import BlockPagination from '@/components/blocks/BlockPagination';
import BlockProgress from '@/components/blocks/BlockProgress';
import TransitionFade from '@/transitions/TransitionFade';
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';


export default {
  components: {
    SubheaderProgram,
    TransitionSlide,
    TransitionFade,
    BlockQuestion,
    BlockProgress,
    BlockPagination,
    ValidationError,
  },

  mixins: [ValidationMixin],

  data() {
    return {
      newAnswers: [],
      currentBlock: undefined,
    };
  },

  computed: {
    ...get('programModule', [
      'isLoading',
      'getBlocks',
      'getBlock',
      'getAnswerByQuestion',
      'getBlockQuestionsAnswer',
      'getAvailableQuestions',
      'getAnswers',
    ]),

    ...get('authModule', ['getUser']),

    user() {
      return this.getUser();
    },

    programId() {
      return this.$route?.params.programId;
    },

    nameProgram() {
      return this.$route?.params.name;
    },

    reportId() {
      return this.$route?.params.reportId;
    },

    blocks() {
      return this.getBlocks();
    },

    questions() {
      if (!this.currentBlock) return undefined;
      return this.getAvailableQuestions(this.currentBlock.questions);
    },

    blockTemplate() {
      return this.currentBlock?.template;
    },

    title() {
      if (!this.currentBlock || !this.currentBlock?.title) return undefined;
      return `BLOCK.${this.currentBlock.title}`;
    },

    iconBlock() {
      if (!this.currentBlock || !this.currentBlock?.icon) return undefined;
      return this.currentBlock.icon;
    },

    blockLast() {
      let block = '';
      if (this.user && this.nameProgram === 'NaturaKosta') {
        block = this.user?.natura?.blockLast;
      } else if (this.user && this.nameProgram === 'Azterkosta') {
        block = this.user?.azterkosta?.blockLast;
      } else if (this.user && this.nameProgram === 'Ibaialde') {
        block = this.user?.ibaialde?.blockLast;
      } else if (this.user && this.nameProgram === 'Natura_mendiak') {
        block = this.user?.natura_mendiak?.blockLast;
      } else if (this.user && this.nameProgram === 'Natura_ibaiak') {
        block = this.user?.natura_ibaiak?.blockLast;
      }

      return block;
    },

  },

  async created() {
    const { programId } = this;
    const { reportId } = this;
    // console.log('Programa id desde created', programId);
    await this.$store.dispatch('programModule/fetchProgram', programId);
    await this.$store.dispatch('programModule/fetchAnswers', reportId);

    this.currentBlock = this.blockLast && this.blockLast?.questions.length > 0 ? this.blockLast : this.blocks[0];
  },

  methods: {
    getAnswer(questionId, questionKey) {
      const answer = this.getAnswerByQuestion(questionId, questionKey);
      return answer?.answer;
    },

    async answerSelected({ questionId, questionKey, answer }) {
      this.addAnswerToNewAnswers({ questionId, questionKey, answer });
      /* console.log('question', questionKey);
      console.log('anwers', answer); */
      await this.$store.dispatch('programModule/saveAnswer', { questionId, questionKey, answer });
    },

    addAnswerToNewAnswers(newAnswer) {
      const answers = this.newAnswers.filter(answer => answer.questionId !== newAnswer.questionId);
      this.newAnswers = [...answers, newAnswer];
    },

    async changeBlock(nextBlock) {
      if (nextBlock) {
        this.currentBlock = this.getBlock(nextBlock);
        await this.$store.dispatch('authModule/fetchUser');
      } else {
        const { reportId } = this;

        console.log('hola qye hace');
        // Cambiar de estado
        await this.$store.dispatch('programModule/updateProgram', { reportId });

        this.$router.push({ name: 'Message', params: { reportId } });
        // this.$router.push({ name: 'Report', params: { reportId } });
      }
    },

    async submitBlock(nextBlock) {
      if (this.ckeckAllBlockQuestions()) {
        await this.send(this.newAnswers);
        this.newAnswers = [];

        this.changeBlock(nextBlock);
      }
    },

    ckeckAllBlockQuestions() {
      const blockQuestionsAnswer = this.getBlockQuestionsAnswer(this.currentBlock.id);
      this.errors = [];
      const validations = blockQuestionsAnswer.map((questionAnswer) => {
        if (!questionAnswer?.validations) return true;

        const valids = questionAnswer?.validations.map(validation => this.checkSpecificValidation(validation, questionAnswer));
        return valids.every(valid => valid);
      });

      return validations.every(validation => validation);
    },

    async send(answers) {
    //  console.log('send', answers);
      await this.$store.dispatch('programModule/sendAnswers', {
        answers,
        blockId: parseInt(this.currentBlock.id, 10),
        programId: this.programId,
        reportId: this.reportId,
      });
    },

    isTemporaryAnswer(questionId) {
      return this.newAnswers.some(answer => answer.questionId === questionId);
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid-template.scss';

.ProgramView {
  background: $color-neutral-lightest;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;

    form {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }
  }
  .block-title {
    background: linear-gradient(90deg, $color-neutral-light, transparent);
    padding: $spacing-m;
    border-left: 3px solid $color-primary-dark;
    width: clamp(340px, 100%, 1440px);
    margin: 0 auto;
    font-size: $font-size-l;
    overflow: hidden;
    .title {
      display: flex;
      color: $color-primary-dark;
      .image {
        width: 3rem;
      }
      p {
        padding: 1rem;
      }
    }
  }

  .block-content {
    // flex: 1;
    display: grid;
    align-content: start;
    padding: $spacing-l;

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  .template {
    padding: $spacing-m;
    // flex: 1;
    display: grid;
    gap: $spacing-m;
    align-content: center;
  }

  .progress {
    display: none;
    @media #{$breakpoint-lg-max} {
      display: flex;
    }
  }
}
</style>
