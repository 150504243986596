<template>
  <div class="ProgramIntroNatura">
    <EmptySubHeader class="subheader" />
    <h1 class="title">
      {{ $t('SELECT.TITLE.PROGRAMNATURA') }}
    </h1>

    <div class="content">
      <div class="Programs">
        <div
          v-for="program in programs"
          :key="program.id"
          :class="program.styleClass"
          :style="{ 'background-image': `url('${program.image}')` }"
        >
          <div class="text-container">
            <div class="button">
              <div>
                <BaseButton
                  size="l"
                  variation="secondary"
                  class="BaseButton"
                  @click="goTo(program.id, program.name)"
                >
                  {{ $t(program.translate) }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="logo-aztertu">
      <a
        href="https://www.euskadi.eus/informacion/programa-aztertu/web01-a2inghez/es/"
        target="_blank"
      >
        <img
          class="logo"
          src="@/assets/images/aztertu_completo.png"
        ></a>
    </div>
  </div>
</template>

<script>
import EmptySubHeader from '@/components/subheader/EmptySubHeader';
// import natura from '@/assets/images/natura.jpg';
import naturaKosta from '@/assets/images/natura-kosta.jpg';
import naturaMendiak from '@/assets/images/natura_mendiak.jpg';
import naturaIbaiak from '@/assets/images/natura_ibaiak.jpg';

export default {
  components: {
    EmptySubHeader,
  },

  data() {
    return {
      programs: [
        {
          id: 3,
          translate: 'TITLE.PROGRAM.NATURAKOSTA',
          styleClass: 'card',
          name: 'NaturaKosta',
          image: naturaKosta,

        },
        {
          id: 4,
          translate: 'TITLE.PROGRAM.NATURA_MENDIAK',
          styleClass: 'card',
          name: 'Natura_mendiak',
          image: naturaMendiak,
        },
        {
          id: 5,
          translate: 'TITLE.PROGRAM.NATURA_IBAIAK',
          styleClass: 'card',
          name: 'Natura_ibaiak',
          image: naturaIbaiak,
        },
      ],
    };
  },

  methods: {
    goTo(programId, name) {
      window.scrollTo(0, 0);
      this.$router.push({ name: 'ProgramIntro', params: { programId, name } });
    },
  },
};
</script>

<style lang="scss" scoped>
.ProgramIntroNatura {
  .title {
    text-align: center;
    padding: 1rem;
    font-size: $font-size-2xl;
    font-weight: 500;

    @media #{$breakpoint-lg-max} {
      margin-top: 1rem;
      padding-top: 3rem;
    }
  }
  .content {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    .Programs {
      margin: 5rem 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      width: 95%;

      .card {
        transition: all 0.25s;
        transition: transform 2s;
        box-shadow: $shadow-elevation-2;
        border-radius: 10px;
        background-size: cover;
        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 20rem;
          transition: transform 1s;

          .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: -3rem;
            background-size: cover;
            width: 100%;
            .BaseButton {
              height: 4rem;
              min-width: 16rem;
              font-weight: 700;
            }
          }
        }
      }
      .disabled{
        filter: grayscale(1);
        .text-container{
        filter: grayscale(1);

        }
      }
      @media #{$breakpoint-md-max} {
        .card {
          width: 100%;
          margin: 0;
          border-radius: 0;
          height: 20rem;
          margin-bottom: 3rem;
          .text-container {
            height: 20rem;
          }
        }
      }
    }
    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  .logo-aztertu{
    display: flex;
    justify-content: center;
    margin-top: -5rem;

    .logo{
      width: 28rem;
      cursor: pointer;

       @media #{$breakpoint-lg-max} {
         width: 19rem;
       }
    }
  }
}
</style>
