<template>
  <div class="ProgramIntroView">
    <div class="content">
      <NewProgram
        v-if="user && stateProgram === state.new"
        :title="title"
        :program-id="programId"
      />

      <ContinueProgram
        v-if="user && stateProgram === state.pending"
        :title="title"
        :program-id="programId"
        :report-id="reportId"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import NewProgram from '@/components/intro/NewProgram';
import ContinueProgram from '@/components/intro/ContinueProgram';

export default {
  components: {
    NewProgram,
    ContinueProgram,
  },

  data() {
    return {
      state: {
        new: 'NEW',
        pending: 'PENDING',
      },

    };
  },

  computed: {

    ...get('authModule', ['getUser']),

    nameProgram() {
      return this.$route?.params.name;
    },

    title() {
      const name = this.$route?.params.name;
      return name.toUpperCase();
    },


    programId() {
      return parseInt(this.$route?.params.programId, 10);
    },

    user() {
      return this.getUser();
    },

    stateProgram() {
      let state = '';
      if (this.user && this.nameProgram === 'NaturaKosta') {
        state = this.user?.natura?.state;
      } else if (this.user && this.nameProgram === 'Azterkosta') {
        state = this.user?.azterkosta?.state;
      } else if (this.user && this.nameProgram === 'Ibaialde') {
        state = this.user?.ibaialde?.state;
      } else if (this.user && this.nameProgram === 'Natura_mendiak') {
        state = this.user?.natura_mendiak?.state;
      } else if (this.user && this.nameProgram === 'Natura_ibaiak') {
        state = this.user?.natura_ibaiak?.state;
      }

      return state;
    },

    reportId() {
      let report = '';
      if (this.user && this.nameProgram === 'NaturaKosta') {
        report = this.user?.natura?.reportId;
      } else if (this.user && this.nameProgram === 'Azterkosta') {
        report = this.user?.azterkosta?.reportId;
      } else if (this.user && this.nameProgram === 'Ibaialde') {
        report = this.user?.ibaialde?.reportId;
      } else if (this.user && this.nameProgram === 'Natura_mendiak') {
        report = this.user?.natura_mendiak?.reportId;
      } else if (this.user && this.nameProgram === 'Natura_ibaiak') {
        report = this.user?.natura_ibaiak?.reportId;
      }

      return report;
    },


  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid-template.scss';

.ProgramIntroView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-image: url('~@/assets/images/f_home.jpg'); */
  background-size: cover;
  opacity:0.8;

  .content {
    justify-content: center;
    background:#fff;
    text-align:center;
    max-width: 1200px;
    margin:auto;
    padding: $spacing-xl;
    box-shadow: $shadow-elevation-1;
    border-radius:6px;
    margin-top: 5rem;
    margin-bottom: 5rem;

    .title{
      font-size: $font-size-4xl;
    }

    .button{
      width:400px;
      .action{
        width: 100%;
      }

    }

    // flex-direction: column;
  }

}
</style>
