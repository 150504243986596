var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BlockPagination" },
    [
      _c("PaginationBullets", {
        attrs: { bullets: _vm.blocks.length, current: _vm.currentBlock }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }