var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProgramIntroNatura" },
    [
      _c("EmptySubHeader", { staticClass: "subheader" }),
      _c("h1", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.$t("SELECT.TITLE.PROGRAMNATURA")) + " ")
      ]),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "Programs" },
          _vm._l(_vm.programs, function(program) {
            return _c(
              "div",
              {
                key: program.id,
                class: program.styleClass,
                style: { "background-image": "url('" + program.image + "')" }
              },
              [
                _c("div", { staticClass: "text-container" }, [
                  _c("div", { staticClass: "button" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            staticClass: "BaseButton",
                            attrs: { size: "l", variation: "secondary" },
                            on: {
                              click: function($event) {
                                return _vm.goTo(program.id, program.name)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t(program.translate)) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      ]),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-aztertu" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.euskadi.eus/informacion/programa-aztertu/web01-a2inghez/es/",
            target: "_blank"
          }
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/images/aztertu_completo.png") }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }