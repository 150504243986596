var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProgramView" },
    [
      _c("SubheaderProgram"),
      _c("TransitionFade", [
        _vm.title
          ? _c(
              "div",
              { staticClass: "block-title" },
              [
                _c("TransitionSlide", { attrs: { side: "left" } }, [
                  _c("div", { key: _vm.title, staticClass: "title" }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: {
                        src: require("@/assets/iconos/" + _vm.iconBlock),
                        alt: "logo"
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.$t(_vm.title)) + " ")])
                  ])
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "content window-fit" }, [
        _c(
          "form",
          {
            on: {
              input: function($event) {
                return _vm.validation($event)
              }
            }
          },
          [
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "block-content" },
                  [
                    _c("TransitionSlide", [
                      _c(
                        "div",
                        {
                          staticClass: "template window-fit",
                          class: [_vm.blockTemplate]
                        },
                        _vm._l(_vm.questions, function(question, i) {
                          return _c(
                            "BlockQuestion",
                            {
                              key: i,
                              attrs: {
                                question: question,
                                value: _vm.getAnswer(question.id, question.key),
                                errors: _vm.errors,
                                temporary: _vm.isTemporaryAnswer(question.id),
                                "allow-modify-answers": true
                              },
                              on: {
                                "answer-selected": _vm.answerSelected,
                                "force-redirect": _vm.submitBlock
                              }
                            },
                            [
                              _c("ValidationError", {
                                attrs: {
                                  errors: _vm.errors,
                                  name: question.key
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c("div", [_c("BaseSpinner")], 1),
            _c(
              "TransitionFade",
              [
                _vm.currentBlock && _vm.blocks && !_vm.isLoading
                  ? _c("BlockPagination", {
                      attrs: {
                        blocks: _vm.blocks,
                        "block-id": _vm.currentBlock.id
                      },
                      on: {
                        "change-block": _vm.changeBlock,
                        "submit-block": _vm.submitBlock
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }