<template>
  <div class="NewProgram">
    <h1 class="title">
      {{ $t(`TITLE.PROGRAM.${title}`) }}
    </h1>

    <!-- <p
      v-html="$t('TEXT.WELCOME.PROGRAM.CONTINUE')"
    /> -->

    <div
      class="description"
      v-html="textDescriptionProgram"
    />

    <img
      class="image"
      :src="require(`@/assets/images/imagenModal${programId}.jpg`)"
    >

    <div class="button">
      <BaseButton
        class="action"
        size="l"
        @click="gotoProgram()"
      >
        {{ $t('BUTTON.CONTINUE') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },

    programId: {
      type: Number,
      default: undefined,
    },

    reportId: {
      type: Number,
      default: undefined,
    },

  },

  computed: {
    textDescriptionProgram() {
      return this.$t(`DESCRIPTION.PROGRAM.${this.programId}`);
    },
  },

  methods: {
    gotoProgram() {
      window.scrollTo(0, 0);
      const { programId } = this;
      const name = this.$route?.params.name;
      const { reportId } = this;

      return this.$router.push({ name: 'Program', params: { programId, name, reportId } });
    },
  },

};
</script>

<style lang="scss" scoped>
.NewProgram {
  // margin-top: 5rem;
  // margin-bottom: 5rem;
  .title {
    font-size: $font-size-4xl;
    margin-bottom: 1rem;
    color: $color-primary;
    font-weight: 600;
  }

  .description {
    text-align: justify;
    font-size: $font-size-l;
    margin-bottom: $spacing-xl;
  }

  .image {
    width: 100%;
    max-height: 400px;
  }

  .button {
    width: 100%;
    margin-top: $spacing-l;
    .action {
      width: 100%;
    }
  }

  p {
    font-size: $font-size-xl;
    margin-bottom: $spacing-m;
    span {
      font-weight: 600;
    }
  }

  /*  @media #{$breakpoint-lg-max} {
    margin-top: 5rem;
  } */
}
</style>
