var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "BreadScrumbs" },
    _vm._l(_vm.breadcrumbs, function(breadcrumb, i) {
      return _c("li", { key: i }, [
        !_vm.isLast(i) && breadcrumb.route
          ? _c(
              "span",
              {
                staticClass: "link",
                attrs: { variation: "tertiary" },
                on: {
                  click: function($event) {
                    return _vm.$router.push(breadcrumb.route)
                  }
                }
              },
              [_vm._v(" " + _vm._s(breadcrumb.name) + " ")]
            )
          : _c("span", [_vm._v(" " + _vm._s(breadcrumb.name) + " ")])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }