<template>
  <div
    class="EmptySubHeader"
    :style="{ 'background-image': `url('${image}')` }"
  >
    <div class="content header window-fit">
      <div class="section">
        <TransitionSlide>
          <div class="button-home">
            <BaseButton
              variation="secondary"
              @click="gotoHome()"
            >
              {{ $t('MENU.TOOLTIP.HOME') }}
            </BaseButton>
          </div>
        </TransitionSlide>

        <TransitionSlide>
          <BreadScrumbs :breadcrumbs="breadcrumbs" />
        </TransitionSlide>


        <TransitionSlide>
          <h1
            class="title"
          >
            {{ title }}
          </h1>
        </TransitionSlide>
      </div>
    </div>
  </div>
</template>

<script>

/* import Natura from '@/assets/images/natura.jpg'; */
import NaturaKosta from '@/assets/images/natura-kosta.jpg';
import Azterkosta from '@/assets/images/azterkosta.jpg';
import Ibaialde from '@/assets/images/ibaialde.jpg';
import TransitionSlide from '@/transitions/TransitionSlide';
import BreadScrumbs from '@/components/subheader/BreadScrumbs';


export default {
  components: {
    TransitionSlide,
    BreadScrumbs,
  },

  data() {
    return {
      breadcrumbs: [],
    };
  },

  computed: {
    image() {
      const nameProgram = this.$route?.params.name;
      let image = '';
      switch (nameProgram) {
        case 'NaturaKosta':
          image = NaturaKosta;
          break;

        case 'Azterkosta':
          image = Azterkosta;
          break;

        case 'Ibaialde':
          image = Ibaialde;
          break;

        default:
          image = Azterkosta;
      }

      return image;
    },

    title() {
      const title = this.$route?.params.name;
      return title.replace('_', '  ');
    },
  },

  methods: {
    gotoHome() {
      window.scrollTo(0, 0);
      if (this.$route?.params.programId < 3) {
        this.$router.push({ name: 'Dashboard' });
      } else { this.$router.push({ name: 'ProgramsNatura' }); }
    },
  },
};
</script>

<style lang="scss" scoped>
.EmptySubHeader {
  padding-top: $theHeaderSpacing;
 // background-image: url('~@/assets/images/f_itinerario.jpg');
  background-size: cover;
  min-height: 220px;
  //filter: grayscale(.7);

   .header {
    padding-top: $theHeaderSpacing;
    min-height: 220px;
    display: grid;

    .section {
      align-self: flex-end;
      padding: $spacing-m;
      // border-left: 3px solid $color-primary;
      border-left: 3px solid $color-primary-light;
      // background: linear-gradient(6deg, #000a, #0000 50%);
      background: linear-gradient(45deg, $color-neutral-darkest, #0000 50%);

      .button-home{
        margin-bottom: $spacing-xl;
      }
      .BreadScrumbs {
        margin-bottom: $spacing-s;
      }

      .title {
        font-size: $font-size-3xl;
        text-transform: uppercase;
        font-weight: 500;
        color: $color-primary-lightest;
        margin-bottom: $spacing-xs;
        padding-bottom: $spacing-2xs;
        border-bottom: 1px solid;
          @media #{$breakpoint-md-max} {
          text-align: center;
        }
      }

      .subtitle {
        color: white;
      }
    }

    @media #{$breakpoint-md-max} {
      flex-direction: column;
    }
  }
}
</style>
