var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "EmptySubHeader",
      style: { "background-image": "url('" + _vm.image + "')" }
    },
    [
      _c("div", { staticClass: "content header window-fit" }, [
        _c(
          "div",
          { staticClass: "section" },
          [
            _c("TransitionSlide", [
              _c(
                "div",
                { staticClass: "button-home" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { variation: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.gotoHome()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.HOME")) + " ")]
                  )
                ],
                1
              )
            ]),
            _c(
              "TransitionSlide",
              [_c("BreadScrumbs", { attrs: { breadcrumbs: _vm.breadcrumbs } })],
              1
            ),
            _c("TransitionSlide", [
              _c("h1", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }